/**
 * Gets the parent domain from the current hostname
 * Used for setting/removing cookies that need to be shared across subdomains
 * 
 * @returns {string|null} The parent domain (e.g. 'nectios.com') or null if running on localhost
 */
export function getDomain() {
  let domain = window.location.host.split('.').splice(-2).join('.');
  if (domain.startsWith('localhost:')) {
    domain = null;
  }

  return domain;
} 